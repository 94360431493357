import {createContext, ReactNode, useContext, useEffect, useMemo} from "react"
import React from 'react'
import {useAppDispatch} from "@src/redux/hooks";
import BrokerService from "@common/services/broker.service";

interface IMqttContext {
    subscribe: (topic: string) => void
    unsubscribe: (topic: string) => void
    publish: (topic: string, message: string) => void
}

const MqttContext = createContext<IMqttContext | undefined>(undefined)

export const useBroker = (): IMqttContext => {
    const context = useContext(MqttContext)
    if (!context) {
        throw new Error('useBroker must be used within a BrokerProvider')
    }
    return context
}

export function BrokerProvider({ children } : { children: ReactNode }) {
    const dispatch = useAppDispatch()
    const broker = useMemo(() => new BrokerService(dispatch), [dispatch])

    useEffect(() => {
        console.log("Connecting to broker")
        broker.connect()
    }, [])

    const subscribe = (topic: string) => {
        broker.subscribe(topic)
    }

    const unsubscribe = (topic: string) => {
        broker.unsubscribe(topic)
    }

    const publish = (topic: string, message: string) => {
        broker.publish(topic, message)
    }


    return (
        <MqttContext.Provider value={{ subscribe, unsubscribe, publish }}>
            {children}
        </MqttContext.Provider>
    )
}