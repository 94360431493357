import {TaggedAction} from "@common/domain/common.props"
import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {
    ChecksheetEntryInfoRef,
    ChecksheetsEntryMinimalRef,
    ChecksheetsMinimalRef,
    ChecksheetsStore,
    ChecksheetTemplateRef
} from "@views/checksheets/domain/checksheets.model"
import AppConstants from "@src/environment/app.constants"
import api from "@common/services/api.service"
import {AxiosResponse} from "axios"
import {createSecuredSlice} from "@utils/auth.utils";


export const assignChecksheets = createAsyncThunk<ApplicationResponse<void>,
    { checksheets: string[], configId: string, department: string }>("checksheet/assign", async (payload, thunkAPI) => {
    try {
        const url = `${AppConstants.api}/checksheet/assign`
        const {checksheets, configId, department} = payload

        const response: AxiosResponse<ApplicationResponse<void>> = await api.put<ApplicationResponse<void>>(url, {
            checksheets,
            configuration: configId,
            department
        })

        return response.data
    } catch (err: any) {
        if (!err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }

        return thunkAPI.rejectWithValue({
            error: {
                message: err.response.data.message
            }
        })
    }
})

export const fetchChecksheetsList = createAsyncThunk<TaggedDataState<ChecksheetsMinimalRef[]>, TaggedAction>("checksheet/fetchChecksheets",
    async (payload) => {
        const {config} = payload.parameters
        let url = `${AppConstants.api}/checksheet/list`

        if (config) {
            url += `?config=${config}`
        }

        const response = await api.get<ApplicationResponse<ChecksheetsMinimalRef[]>>(url)

        return {
            tag: payload.tag,
            data: response.data.data || []
        }

    })

export const fetchChecksheetEntries = createAsyncThunk<TaggedDataState<ChecksheetsEntryMinimalRef[]>, TaggedAction>("checksheet/fetchChecksheetEntries",
    async (payload) => {
        const {config, checksheet} = payload.parameters
        let url = `${AppConstants.api}/checksheet/entries?checksheet=${checksheet}`
        if (config) {
            url += `&configId=${config}`
        }

        const response = await api.get<ApplicationResponse<ChecksheetsEntryMinimalRef[]>>(url)

        return {
            tag: payload.tag,
            data: response.data.data || []
        }

    }
)

export const fetchChecksheetEntryInfo = createAsyncThunk<ChecksheetEntryInfoRef | undefined, string>("checksheet/fetchChecksheetEntryInfo",
    async (id) => {
        const response = await api.get<ApplicationResponse<ChecksheetEntryInfoRef>>(`${AppConstants.api}/checksheet/entry?id=${id}`)
        const data: ChecksheetEntryInfoRef | undefined = response.data.data

        return data
    })

export const fetchChecksheetTemplate = createAsyncThunk<any | undefined, string>("checksheet/fetchTemplate",
    async (id) => {
        const response = await api.get<ApplicationResponse<any>>(`${AppConstants.api}/checksheet/template?id=${id}`)
        const data: ChecksheetTemplateRef = ChecksheetTemplateRef.fromMap(response.data.data)
        return data.toPlainObject()
    })


const checksheetSlice = createSecuredSlice({
    name: "checksheets",
    initialState: {
        minimal: {},
        entries: {},
        info: {status: "loading"},
        template: {status: "loading"}
    } as ChecksheetsStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChecksheetsList.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta

                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchChecksheetsList.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.minimal[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }

                })
            .addCase(fetchChecksheetsList.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.minimal[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
            .addCase(fetchChecksheetEntries.pending, (state, action) => {
                const {arg: {tag}} = action.meta

                state.entries[tag] = {
                    status: "loading",
                    error: null,
                    data: []
                }
            })
            .addCase(fetchChecksheetEntries.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.entries[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchChecksheetEntries.rejected, (state, action) => {
                const {arg: {tag}} = action.meta
                state.entries[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
            .addCase(fetchChecksheetEntryInfo.pending, (state) => {
                state.info = {
                    status: "loading",
                    error: null,
                    data: undefined
                }
            })
            .addCase(fetchChecksheetEntryInfo.fulfilled, (state, {payload}) => {
                state.info = {
                    status: "idle",
                    error: null,
                    data: payload
                }
            })
            .addCase(fetchChecksheetEntryInfo.rejected, (state, action) => {
                state.info = {
                    status: "failed",
                    error: action.error.message,
                    data: undefined
                }
            })
            .addCase(fetchChecksheetTemplate.pending, (state) => {
                state.template = {
                    status: "loading",
                    error: null,
                    data: undefined
                }
            })
            .addCase(fetchChecksheetTemplate.fulfilled, (state, {payload}) => {
                state.template = {
                    status: "idle",
                    error: null,
                    data: payload
                }
            })
            .addCase(fetchChecksheetTemplate.rejected, (state, action) => {
                state.template = {
                    status: "failed",
                    error: action.error.message,
                    data: undefined
                }
            })
    }
})

export default checksheetSlice.reducer