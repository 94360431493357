import {createSlice} from "@reduxjs/toolkit";
import {createSecuredSlice} from "@utils/auth.utils";

interface BrokerStore {
    [topic: string] : any | null
}

const brokerSlice = createSecuredSlice({
    name: 'broker',
    initialState: {} as BrokerStore,
    reducers: {
        updateMessage(state, action) {
            const {topic, message} = action.payload
            state[topic] = message
        },
        clearMessage(state, action) {
            const {topic} = action.payload
            delete state[topic]
        }
    }
})

export const {updateMessage, clearMessage} = brokerSlice.actions

export default brokerSlice.reducer