import AppConstants from '@src/environment/app.constants';
import { ApplicationResponse, StateTrackedData } from './../../common/domain/common.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@src/common/services/api.service';
import { DailyModelMinimalRef, ReportStore } from '@src/views/dashboard/domain/dashboard.model';
import {createSecuredSlice} from "@utils/auth.utils";



export const fetchHourlyModelData = createAsyncThunk(
    "dashboard/fetchHourlyModelInfo",
    async (modelId?: string) => {
      let url = AppConstants.api+"/entry";
      if (modelId) {
        url += `/${modelId}/report/hourly`;
      } else {
        url += `/report/hourly`;
      }
      const response = await api.get<ApplicationResponse<DailyModelMinimalRef[]>>(url);
      return response.data.data;
    }
  );
  
  export const fetchDailyModelData = createAsyncThunk(
    "dashboard/fetchDailyModelInfo",
    async (modelId?: string) => {
      let url = AppConstants.api+"/entry";
      if (modelId) {
        url += `/${modelId}/report/daily`; 
      } else {
        url += `/report/daily`;
      }
  
      const params={
          startDate:"01-03-2024",
          endDate:"20-03-2024",
        }
      const response = await api.get<ApplicationResponse<DailyModelMinimalRef[]>>(url,{
          params
      });
  
      return response.data.data;
    }
  );
  

const reportSlice = createSecuredSlice({
    name: "reports",
    initialState: {
      dailyMinimal: {},
      hourlyMinimal: {},
    }  as ReportStore,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchDailyModelData.pending, (state) => {
          state.dailyMinimal = {
            status: "loading",
            error: null,
            data: undefined
          }
        })
        .addCase(fetchDailyModelData.fulfilled, (state, action) => {
            state.dailyMinimal = {
                status: "idle",
                error: null,
                data: action.payload
              }
        })
        .addCase(fetchDailyModelData.rejected, (state, action) => {
            state.dailyMinimal = {
                status: "failed",
                error: action.error.message,
                data: undefined
            }
        })
  
        .addCase(fetchHourlyModelData.pending, (state) => {
            state.hourlyMinimal = {
                status: "loading",
                error: null,
                data: undefined
              }
        })
        .addCase(fetchHourlyModelData.fulfilled, (state, action) => {
            state.hourlyMinimal = {
                status: "idle",
                error: null,
                data: action.payload
              }
        })
        .addCase(fetchHourlyModelData.rejected, (state, action) => {
            state.hourlyMinimal = {
                status: "failed",
                error: action.error.message,
                data: undefined
              }
        })
        ;
    },
  });
  
  export default reportSlice.reducer;
  