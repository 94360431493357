const AppConstants = {
    api: 'https://api.digimach.io',
    // api: 'http://localhost:5001',
    mqtt: {
        url: 'wss://broker.api.digimach.io:9000',
        protocol: 'wss',
        port: 9000,
        host: 'broker.digimach.io',
        username: 'mqtt',
        password: 'mqtt'
    },

    // api: 'http://digimach.local:5000',
    //
    // mqtt: {
    //     url: 'ws://digimach.local:6969',
    //     protocol: 'ws',
    //     port: 6969,
    //     host: 'digimach.local',
    //     username: 'fapladmin',
    //     password: '2/z29O4lbesHwWLr11GYK1XC2LHY7q2k/UV9MOcHyzk='
    // }
}

export default AppConstants
