import {createAsyncThunk, createSlice, SerializedError} from "@reduxjs/toolkit"
import {ApplicationResponse, TaggedDataState} from "@src/common/domain/common.model"
import {TaggedAction} from "@src/common/domain/common.props"
import AppConstants from "@src/environment/app.constants"
import api from "@common/services/api.service"
import {HolidayMinimalRef, NewHolidayRef, WeekdayMinimalRef} from "@views/pms/domain/pms.model"
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchWeekdayList =
    createAsyncThunk<TaggedDataState<WeekdayMinimalRef[]>, TaggedAction>("holiday/weekdayList",
        async (payload) => {
            const url = `${AppConstants.api}/weekday`
            const response = await api.get<ApplicationResponse<WeekdayMinimalRef[]>>(url)
            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })


export const updateWeekdays = createAsyncThunk<void, {
    payload: WeekdayMinimalRef[]
}>("weekdays/upload",
    async ({payload}, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/weekday`
            const response = await api.put<ApplicationResponse<void>>(url, payload)
            return response.data.data

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

export const fetchHolidayList =
    createAsyncThunk<TaggedDataState<HolidayMinimalRef[]>, TaggedAction>("holiday/holidayList",
        async (payload) => {
            const url = `${AppConstants.api}/holiday`
            const response = await api.get<ApplicationResponse<HolidayMinimalRef[]>>(url)
            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })

export const uploadHoliday = createAsyncThunk<void, {
    payload: NewHolidayRef
}>("holiday/upload",
    async ({payload}, thunkAPI) => {
        try {

            const url = `${AppConstants.api}/holiday`

            const apiPayload = {
                id: payload.id,
                name: payload.name,
                date: payload.date,

            }
            if (payload.id) {
                const response = await api.put<ApplicationResponse<void>>(url, apiPayload)
                return response.data.data
            } else {
                const response = await api.post<ApplicationResponse<void>>(url, apiPayload)
                return response.data.data
            }
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

export const deleteHoliday = createAsyncThunk<void, {
    // configId: string,
    id: string
},
    { rejectValue: SerializedError }>("holiday/deleteHoliday",
    async (payload, thunkAPI) => {
        try {
            const {id} = payload

            const response = await api.delete(`${AppConstants.api}/holiday?id=${id}`)

            return response.data

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue({
                    name: "NetworkError",
                    message: "Network error",
                    stack: err.stack,
                })
            }

            return thunkAPI.rejectWithValue({
                name: "ApiError",
                message: "Error from API",
                stack: err.stack,
            })
        }
    }
)

const holidaySlice = createSecuredSlice({
    name: "holiday",
    initialState: {weekday: {}, holiday: {}} as any,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWeekdayList.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.weekday[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchWeekdayList.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.weekday[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }
                })
            .addCase(fetchWeekdayList.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.weekday[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
            .addCase(fetchHolidayList.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.holiday[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchHolidayList.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.holiday[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }
                })
            .addCase(fetchHolidayList.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.holiday[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
    }
})

export default holidaySlice.reducer