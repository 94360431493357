import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import {createAsyncThunk, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import {DayOverview} from "@components/calender"
import AppConstants from "@src/environment/app.constants"
import api from "@src/common/services/api.service"
import moment from "moment-timezone"
import {EntryMinimalRef, EntryStore, NewEntryRef} from "@views/pms/domain/entries.model";
import {createSecuredSlice} from "@utils/auth.utils";


export const MockedEntryHistory: DayOverview<any>[] = [
    {
        date: "2024-02-20",
        events: [
            {id: 1, name: "Shift A", time: "10:00 AM", href: "#"},
            {id: 2, name: "Shift B", time: "11:00 AM", href: "#"},
            {id: 3, name: "Shift C", time: "12:00 PM", href: "#"}
        ],
    },
    {
        date: "2024-02-02",
        events: [
            {id: 4, name: "Shift D", time: "9:00 AM", href: "#"}
        ]
    }
]

export const fetchEntriesBetweenDates =
    createAsyncThunk<TaggedDataState<EntryMinimalRef[]>, TaggedAction>("entries/fetchEntriesBetweenDates",
        async (payload) => {
            const {assemblyId, entryStartDate, entryEndDate} = payload.parameters
            const url = `${AppConstants.api}/entry?lineId=${assemblyId}&startDate=${entryStartDate}&endDate=${entryEndDate}`

            const response = await api.get<ApplicationResponse<EntryMinimalRef[]>>(url)

            const data = response.data.data ?? []

            return {
                tag: payload.tag,
                // data: MockData
                data: data

            }

        }
    )

export const uploadEntry = createAsyncThunk<void, {
    payload: NewEntryRef
}>("shift/upload",
    async ({payload}, thunkAPI) => {

        try {

            const url = `${AppConstants.api}/entry`
 
            let finalPayload;
                if (payload.id) {
                    finalPayload = {
                        id:payload.id,
                        start_time: payload.startTime,
                        end_time: payload.endTime,
                        production_target: payload.productionTarget,
                        title: payload.title,
                        model:payload.modelId,
                        oee_target: payload.oeeTarget,
                        rejection_threshold: payload.rejectionThreshold,
                        activity_minimal: payload.activities.map((activity: any) => ({
                            id: activity.id,
                            name: activity.name,
                            is_custom : activity.isCustom,
                            start_time: activity.startTime,
                            duration: activity.duration * 60,
                            date:activity.date,
                        })),
                    };

                } else {
                    finalPayload = {
                        ...payload,
                        modelId: payload.modelId,
                        startDate: moment(payload.startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                        endDate: moment(payload.endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                        activities: payload.activities.map((activity: any) => ({
                            ...activity,
                            duration: activity.duration * 60,
                            
                        })),
                    };
                }
                
            if (payload.id) {
                const response = await api.put<ApplicationResponse<void>>(url, finalPayload)
                return response.data.data
            } else {
                const response = await api.post<ApplicationResponse<void>>(url, finalPayload)
            return response.data.data
            }

            
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

const plannerSlice = createSecuredSlice({
    name: "pms",
    initialState: {minimal: {}} as EntryStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEntriesBetweenDates.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchEntriesBetweenDates.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchEntriesBetweenDates.rejected, (state,
                                                         action: PayloadAction<unknown, string,
                                                             { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state.minimal[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default plannerSlice.reducer