import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import convertToForm from "@utils/payload-to-form-converter"
import {
    MachineManualMinimalRef,
    MachineManualStore,
    NewMachineManualRef
} from "@views/machine-manual/domain/machine-manual.model"
import AppConstants from "@src/environment/app.constants"
import {createSecuredSlice} from "@utils/auth.utils";
// import { NewOnePointLessonRef } from "@views/opl/domain/opl.model"


export const fetchMachineManualList = createAsyncThunk<TaggedDataState<MachineManualMinimalRef[]>, TaggedAction>("machineManuals/fetchManuals",
    async (payload) => {
        const {config} = payload.parameters

        const response = await api.get<ApplicationResponse<MachineManualMinimalRef[]>>(`${AppConstants.api}/manuals/list?config=${config}`)

        return {
            tag: payload.tag,
            data: response.data.data || []
        }

    })

export const uploadMachineManual =
    createAsyncThunk<any, {
        config?: string,
        feature?: string,
        payload: NewMachineManualRef
    }>("machineManuals/upload",
        async (wrapper, thunkAPI) => {
            try {
                const url = `${AppConstants.api}/manuals/new`
                const {
                    config,
                    payload,
                    feature
                } = wrapper
                const keys = Object.keys(payload) as Array<keyof NewMachineManualRef>
                const formData = convertToForm(payload, keys)

                if (config) {
                    formData.append("configurationId", config)
                }

                if (feature) {
                    formData.append("featureId", feature)
                }

                const response = await api.post<ApplicationResponse<void>>(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })

                return response.data
            } catch (err: any) {
                if (!err.response) {
                    return thunkAPI.rejectWithValue(err.response.data)
                }

                return thunkAPI.rejectWithValue({
                    error: {
                        message: "Network error",
                        code: "NETWORK_ERROR",
                        attributes: {}
                    },
                    status: "error",
                    timestamp: new Date().toISOString()
                })
            }
        })

export const deleteMachineManuals = createAsyncThunk<void, {
    configId: string,
    manualId: string
},
    { rejectValue: SerializedError }>("machineManuals/deleteMachineManuals",
    async (payload, thunkAPI) => {
        try {
            const {configId, manualId} = payload

            const response = await api.delete(`${AppConstants.api}/manuals/remove?id=${manualId}&configId=${configId}`)

            return response.data

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue({
                    name: "NetworkError",
                    message: "Network error",
                    stack: err.stack,
                })
            }

            return thunkAPI.rejectWithValue({
                name: "ApiError",
                message: "Error from API",
                stack: err.stack,
            })
        }
    }
)

const machineManualsSlice = createSecuredSlice({
    name: "machineManuals",
    initialState: {} as MachineManualStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMachineManualList.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchMachineManualList.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload

                state[tag] = {
                    status: "idle",
                    error: null,
                    data
                }
            })
            .addCase(fetchMachineManualList.rejected, (state,
                                                       action: PayloadAction<unknown, string,
                                                           { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta

                state[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default machineManualsSlice.reducer