import mqtt, {MqttClient, MqttProtocol} from 'mqtt'
import AppConstants from "@src/environment/app.constants";
import {AppDispatch} from "@store";
import {clearMessage, updateMessage} from "@slices/broker.slice";

class BrokerService {
    broker: MqttClient | null;
    dispatch: AppDispatch

    constructor(dispatch: AppDispatch) {
        this.broker = null;
        this.dispatch = dispatch;
    }

    connect() {
        this.broker = mqtt.connect( AppConstants.mqtt.url,{
            username: AppConstants.mqtt.username,
            password: AppConstants.mqtt.password,
            protocol: AppConstants.mqtt.protocol as MqttProtocol,
            protocolVersion: 5,
        })

        this.broker.on('message', (topic, message) => {
            const data = JSON.parse(message.toString())
            this.dispatch(updateMessage({topic, message: data}))
        })
    }

    isConnected() {
        return this.broker?.connected ?? false
    }

    subscribe(topic: string) {
        if (!this.broker) {
            throw new Error('Broker not connected')
        }

        this.broker.subscribe(topic, { qos: 1 } ,(err) => {
            if (err) {
                console.error(err)
                return
            }
        })
    }

    unsubscribe(topic: string) {
        if (!this.broker) {
            throw new Error('Broker not connected')
        }

        this.broker.unsubscribe(topic, () => {
            this.dispatch(clearMessage({topic}))
        })
    }

    publish(topic: string, message: string) {
        if (!this.broker) {
            throw new Error('Broker not connected')
        }

        this.broker.publish(topic, message)
    }

}

export default BrokerService