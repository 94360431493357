import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import AppConstants from "@src/environment/app.constants"
import {PmsStore, ProductionHistoryRef} from "@src/views/pms/domain/pms.model"
import moment from "moment"
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchProductionHistory =
    createAsyncThunk<TaggedDataState<ProductionHistoryRef[]>, TaggedAction>("productionHistory/fetchProductionHistory",
        async (payload) => {
            const {assemblyId, entryStartDate, entryEndDate} = payload.parameters
            const url = `${AppConstants.api}/entry?lineId=${assemblyId}&startDate=${entryStartDate}&endDate=${entryEndDate}`

            const response = await api.get<ApplicationResponse<ProductionHistoryRef[]>>(url)

            const formattedData = response.data.data?.map(entry => ({
                ...entry,
                date: moment(entry.date, "DD-MM-YYYY").format('DD MMMM YYYY'),
            }));

            return {
                tag: payload.tag,
                data: formattedData || [],
            };
        }
    )

const pmsSlice = createSecuredSlice({
    name: "pms",
    initialState: {minimal: {}} as PmsStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductionHistory.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchProductionHistory.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchProductionHistory.rejected, (state,
                                                       action: PayloadAction<unknown, string,
                                                           { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state.minimal[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default pmsSlice.reducer