import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import api from "@common/services/api.service"
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {StationStore, StationViewRef} from "@views/stations/domain/stations.model"
import AppConstants from "@src/environment/app.constants"
import {TaggedAction} from "@src/common/domain/common.props"
import {StationSearchRef} from "@src/views/assembly-lines/domain/lines.model"
import {createSecuredSlice} from "@utils/auth.utils";


export const fetchStationInfo = createAsyncThunk<StationViewRef | undefined, string>("stations/fetchStationInfo",
    async (id) => {

        try {
            const response = await api.get<ApplicationResponse<StationViewRef>>(`${AppConstants.api}/station/info?id=${id}`)

            if (response.data.data) return response.data.data
        } catch (err) {
            console.error(err)
        }
    })

export const fetchStationSearch = createAsyncThunk<TaggedDataState<any>, TaggedAction>("stations/searchStations",
    async (payload) => {
        const {lineId} = payload.parameters
        let url = `${AppConstants.api}/station/search?`
        if (lineId) url += `lineId=${lineId}`
        const response = await api.get<ApplicationResponse<StationSearchRef[]>>(url)

        return {
            tag: payload.tag,
            data: response.data.data
        }
    })

export const publishConfiguration = createAsyncThunk<void, string>("stations/publishConfiguration",
    async (id) => {
        await api.post(`${AppConstants.api}/devices/publish?id=${id}`, {})
    })

const stationSlice = createSecuredSlice({
    name: "stations",
    initialState: {search: {}} as StationStore,
    reducers: {
        setViewConfig: (state, {payload}) => {
            state.viewConfig = payload
        },
        resetViewConfig: (state) => {
            state.viewConfig = undefined
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStationInfo.pending,
                (state) => {
                    state.info = {
                        status: "loading",
                        error: null,
                        data: undefined
                    }
                })
            .addCase(fetchStationInfo.fulfilled, (state, {payload}) => {
                state.info = {
                    status: "idle",
                    error: null,
                    data: payload
                }
            })
            .addCase(fetchStationInfo.rejected, (state, action) => {
                state.info = {
                    status: "failed",
                    error: action.error.message,
                    data: undefined
                }
            })
            .addCase(fetchStationSearch.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta

                    state.search[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchStationSearch.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.search[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }

                })
            .addCase(fetchStationSearch.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.search[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
    }
})

export const {setViewConfig, resetViewConfig} = stationSlice.actions

export default stationSlice.reducer