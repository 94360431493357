import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import convertToForm from "@utils/payload-to-form-converter"
import AppConstants from "@src/environment/app.constants"
import {DrawingMinimalRef, DrawingStore, NewDrawingsRef} from "@src/views/drawings/domain/drawings.model"
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchDrawingsList =
    createAsyncThunk<TaggedDataState<DrawingMinimalRef[]>, TaggedAction>("drawings/fetchdrawings",
        async (payload) => {
            const {config} = payload.parameters

            const response = await api.get<ApplicationResponse<DrawingMinimalRef[]>>(`${AppConstants.api}/drawing/list?config=${config}`)

            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })

export const uploadDrawing = createAsyncThunk<any, {
    config?: string,
    feature?: string,
    payload: NewDrawingsRef
}>("drawings/upload",
    async (wrapper, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/drawing/new`
            const {
                config,
                feature,
                payload
            } = wrapper

            const keys = Object.keys(payload) as Array<keyof NewDrawingsRef>
            const formData = convertToForm(payload, keys)


            if (config) {
                formData.append("configurationId", config)
            }

            if (feature) {
                formData.append("featureId", feature)
            }

            const response = await api.post<ApplicationResponse<DrawingMinimalRef>>(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })

            return response.data.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })


const drawingsSlice = createSecuredSlice({
    name: "drawings",
    initialState: {} as DrawingStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDrawingsList.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchDrawingsList.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchDrawingsList.rejected, (state,
                                                  action: PayloadAction<unknown, string,
                                                      { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default drawingsSlice.reducer