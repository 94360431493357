import { ApplicationResponse } from "@common/domain/common.model";
import { TaggedAction } from "@common/domain/common.props";
import api from "@common/services/api.service";
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import convertToForm from "@utils/payload-to-form-converter";
import AppConstants from "@src/environment/app.constants";
import {
  DepartmentMinimalRef,
  DepartmentStore,
  NewDepartmentRef,
} from "@src/views/assembly-lines/domain/lines.model";
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchDepartmentsList = createAsyncThunk<
  DepartmentMinimalRef[],
  TaggedAction
>("department/fetchDepartment", async () => {
  const response = await api.get<ApplicationResponse<DepartmentMinimalRef[]>>(
    `${AppConstants.api}/departments`
  );
  return response.data.data || [];
});

export const uploadDepartment = createAsyncThunk<
  any,
  {
    payload: NewDepartmentRef;
  }
>("department/upload", async ({ payload }, thunkAPI) => {
  try {
    const url = `${AppConstants.api}/departments${ payload.id ? `/${payload.id}` : "" }`
   
    const finalPayload = payload.id
      ? {
          id: payload.id,
          name: payload.name,
          description: payload.description,
        }
      : {
          ...payload,
        };

    const response = payload.id
      ? await api.put<ApplicationResponse<void>>(url, finalPayload)
      : await api.post<ApplicationResponse<void>>(url, finalPayload);

    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      return thunkAPI.rejectWithValue(err.response.data);
    }

    return thunkAPI.rejectWithValue({
      error: {
        message: err.response.data.message,
        code: err.response.data.code,
      },
    });
  }
});
export const deleteDepartment = createAsyncThunk<
  void,
  {
    id: string;
  },
  { rejectValue: SerializedError }
>("department/deleteDepartment", async (payload, thunkAPI) => {
  try {
    const { id } = payload;

    const response = await api.delete(`${AppConstants.api}/departments/${id}`);

    return response.data;
  } catch (err: any) {
    if (!err.response) {
      return thunkAPI.rejectWithValue({
        name: "NetworkError",
        message: "Network error",
        stack: err.stack,
      });
    }

    return thunkAPI.rejectWithValue({
      name: "ApiError",
      message: "Error from API",
      stack: err.stack,
    });
  }
});

const departmentSlice = createSecuredSlice({
  name: "department",
  initialState: { minimal: { status: "empty" } } as DepartmentStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchDepartmentsList.pending,
        (state, action: { meta: { arg: TaggedAction } }) => {
          state.minimal = {
            status: "loading",
            error: null,
            data: [],
          };
        }
      )
      .addCase(fetchDepartmentsList.fulfilled, (state, action) => {
        const data = action.payload;
        state.minimal = {
          status: "idle",
          error: null,
          data,
        };
      })
      .addCase(
        fetchDepartmentsList.rejected,
        (
          state,
          action: PayloadAction<
            unknown,
            string,
            { arg: TaggedAction },
            SerializedError
          >
        ) => {
          state.minimal = {
            status: "failed",
            error: action.error.message,
            data: [],
          };
        }
      );
  },
});
export default departmentSlice.reducer;
