import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { ApplicationResponse, TaggedDataState } from "@src/common/domain/common.model";
import { TaggedAction } from "@src/common/domain/common.props";
import api from "@src/common/services/api.service";
import AppConstants from "@src/environment/app.constants";
import { NewQNRef, QNInfoRef, QnInfoStore } from "@src/views/plant-info/domain/plant-info.model";
import {createSecuredSlice} from "@utils/auth.utils";

export const MockedIncident: any = [
    {
        days: 12
    }
]


export const fetchQnInfo =
    createAsyncThunk<TaggedDataState<QNInfoRef[]>, TaggedAction>("qnInfo/fetchIncidentInfo",
        async (payload) => {
            const url = `${AppConstants.api}/complaint/latest`

            const response = await api.get<ApplicationResponse<QNInfoRef[]>>(url)
           
            return {
                tag: payload.tag,
                data: response.data.data || []
                // data:MockedIncident

            };

        }
    )

    export const registerQN = createAsyncThunk<void, {
        payload: NewQNRef
    }>("complaint/upload",
        async ({payload}, thunkAPI) => {
            try {
    
                const url = `${AppConstants.api}/complaint`
    
                    const response = await api.post<ApplicationResponse<void>>(url, payload)
                    return response.data.data
                
            } catch (err: any) {
                if (!err.response) {
                    return thunkAPI.rejectWithValue(err.response.data)
                }
    
                return thunkAPI.rejectWithValue({
                    error: {
                        message: err.response.data.message,
                        code: err.response.data.code
                    }
                })
            }
        })

const qnInfoSlice = createSecuredSlice({
    name: "qn",
    initialState: { minimal: {} } as any,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchQnInfo.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const { arg: { tag } } = action.meta

                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchQnInfo.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchQnInfo.rejected, (state,
                action: PayloadAction<unknown, string,
                    { arg: TaggedAction }, SerializedError>) => {
                const { arg: { tag } } = action.meta
                state.minimal[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})


export default qnInfoSlice.reducer

