import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import {
    NewWorkInstructionRef,
    WorkInstructionsMinimalRef,
    WorkInstructionsStore
} from "@src/views/work-instructions/domain/work-instructions.model"
import convertToForm from "@utils/payload-to-form-converter"
import AppConstants from "@src/environment/app.constants"
import {createSecuredSlice} from "@utils/auth.utils";

export const uploadWorkInstruction = createAsyncThunk<any, {
    config?: string,
    feature?: string,
    payload: NewWorkInstructionRef
}>("workInstructions/upload", async (wrapper, thunkAPI) => {
    try {
        const url = `${AppConstants.api}/wi/new`
        const {
            config,
            feature,
            payload
        } = wrapper

        const keys = Object.keys(payload) as Array<keyof NewWorkInstructionRef>
        const formData = convertToForm(payload, keys)

        if (config) {
            formData.append("configuration", config)
        }

        if (feature) {
            formData.append("feature", feature)
        }

        const response = await api.post <ApplicationResponse<void>>(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })

        return response.data
    } catch (err: any) {
        if (!err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }

        return thunkAPI.rejectWithValue({
            error: {
                message: "Network error",
                code: "NETWORK_ERROR",
                attributes: {}
            },
            status: "error",
            timestamp: new Date().toISOString()
        })
    }
})

export const deleteWorkInstruction = createAsyncThunk<void, {
    wiSetId: string,
    wiId: string;
},
    { rejectValue: SerializedError }>("workInstructions/delete",
    async (payload, thunkAPI) => {
        try {
            const {wiSetId, wiId} = payload

            const response = await api.delete(`${AppConstants.api}/wi/remove?wi_set_id=${wiSetId}&wi_id=${wiId}`)

            return response.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue({
                    name: "NetworkError",
                    message: "Network error",
                    stack: err.stack,
                })
            }

            return thunkAPI.rejectWithValue({
                name: "ApiError",
                message: "Error from API",
                stack: err.stack,
            })
        }
    }
)

export const fetchWorkInstructionsList =
    createAsyncThunk<TaggedDataState<WorkInstructionsMinimalRef[]>, TaggedAction>("workInstructions/fetchWorkInstructions",
        async (payload) => {
            const {config} = payload.parameters

            const response = await api.get<ApplicationResponse<WorkInstructionsMinimalRef[]>>(`${AppConstants.api}/wi/list?config=${config}`)

            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })

const workInstructionsSlice = createSecuredSlice({
    name: "workInstructions",
    initialState: {} as WorkInstructionsStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkInstructionsList.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchWorkInstructionsList.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchWorkInstructionsList.rejected, (state,
                                                          action: PayloadAction<unknown, string,
                                                              { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default workInstructionsSlice.reducer